@import url(https://fonts.googleapis.com/css2?family=Manrope&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Palanquin+Dark:wght@400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);

body {
  width: 100vw;
  white-space: normal;
  color: #080808;
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 50px;
  background-color:#ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes reveal {
  from {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px);
    -webkit-transform: rotate3d(1, 0, 0, 45deg);
            transform: rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }

  to { 
    -webkit-filter: blur(0); 
            filter: blur(0);
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transform: rotate3d(0, 0, 0, 0deg);
            transform: rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
}

@keyframes reveal {
  from {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px);
    -webkit-transform: rotate3d(1, 0, 0, 45deg);
            transform: rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }

  to { 
    -webkit-filter: blur(0); 
            filter: blur(0);
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transform: rotate3d(0, 0, 0, 0deg);
            transform: rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
}

.rotated {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

@charset "UTF-8";
.container {
  margin-left: 15px;
  width: calc(100vw - 30px);
}

@media screen and (min-width: 1000px) {
  .container {
    width: 900px;
    margin-left: calc(50vw - 450px);
    margin-right: 0px;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    width: 1300px;
    margin-left: calc(50vw - 650px);
    margin-right: 0px;
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 110px;
  background-color: #fafafaec;
  box-shadow: 0px 0px 100px 0px #b6b6b6ce;
  top: -20px;
  margin-top: 20px;
  z-index: 1000;
}

.header-top {
  top: 0px;
  background-color: #8b8b8b25;
  height: 40px;
  text-align: right;
}

.header-top-card {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.header-top-card-text {
  vertical-align: top;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
  margin-top: 1px;
}

.header-top-card-icon {
  vertical-align: top;
  display: inline-block;
  width: 15px;
  fill: #0e2f47;
}

.header-logo {
  margin-top: 5px;
  margin-left: 0px;
}

.header-logo-icon {
  margin-top: 5px;
  margin-right: 20px;
  display: inline-block;
  width: 60px;
}

.header-logo-text {
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  color: #323236;
  border-left: 1px solid #d3d3d3;
  padding-left: 20px;
}

.header-card {
  display: block;
  text-align: right;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: calc(100% - 40px) 30px;
}

.header-card-icon {
  width: 15px;
  height: 15px;
  fill: rgb(46, 46, 46);
}

.header-card-text {
  font-weight: 700;
  font-size: 12px;
}

.header-container {
  margin-top: 20px;
  margin-right: 140px;
  float: right;
}

.header-desktop {
  display: inline-block;
  margin-top: -80px;
}

.header-mobile {
  display: inline-block;
  margin-top: -80px;
  margin-right: 80px;
}

.header-phone {
  margin-top: 5px;
  margin-right: 20px;
  float: right;
  display: grid;
  grid-template-columns: calc(100% - 30px) 30px;
}


.header-phone-icon {
  margin-left: 15px;
  width: 25px;
  stroke: #101111;
}

.header-phone-text {
  color: #080808;
  font-weight: 700;
  margin-top: 3px;
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
}

.header-right {
  margin-right: 250px;
}

.header-phone-lt {
  display: inline-block;
  white-space: nowrap;
}

.header-phone-lt-numbers {
  text-decoration: none;
  color: black;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  margin-right: 10px;
  font-weight: 700;
}

.header-phone-rt {
  display: inline-block;
  padding-left: 10px;
}

@media screen and (min-width: 1000px) {

  .header-inside {}

  .header-phone {
    margin-top: -40px;
    margin-right: 35px;
  }

  .header-phone-text {
    font-size: 20px;
    font-weight: 500;
    margin-top: -1px;
    margin-right: 5px;
  }

  .header-right {
    float: right;
    margin-top: -100px;
    margin-right: 150px;
  }
  
  .header-phone-rt {
    margin-right: 0px;
  }
}

.header-phone:hover .header-phone-icon {
  stroke: #1169a8;
}

.header-phone:hover {
  color: #1169a8;
}

.header-card-text {
  color: #101111;
  font-size: 11px;
}

@media screen and (max-width: 1000px) {
  .header {
    height: 30px;
    position: fixed;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100vw - 0px);
    margin-top: 20px;
    border-radius: 0px;
  }

  .header-top {
    display: none;
  }

  .header-inside {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .header-container {
    margin-top: 0px;
  }

  .header-logo-icon {
    width: 50px;
    margin-top: -10px;
    margin-left: 0px;
    vertical-align: top;
    display: inline-block;
  }

  .header-logo-text {
    display: none;
  }

  .header-desktop {
    display: none;
    opacity: 0;
  }

  .header-mobile {
    text-align: right;
    margin-right: 20px;
  }

  .header-card {
    display: block;
    margin-bottom: 5px;
  }

  .header-card-text {
    font-size: 15px;
    font-weight: 600;
  }

  .header-card-icon {
    margin-left: 20px;
  }

  .header-phone-text {
    margin-right: 0px;
  }
}

.banner {
  margin-top: -350px;
  position: relative;
  padding-top: 200px;
}

.banner-landing {
  position: relative;
  height: 80vh;
  min-height: 800px;
  max-height: 1500px;
}

.banner-container {
  position: absolute;
  top: -150px;
  left: -25px;
  width: 80vw;
}


@media screen and (min-width: 1000px) {
  .banner-container {
    top: 60px;
    left: 10px;
    width: 1000px;
    margin-left: 50vw - 500px;
  }
}

.banner-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 90vh;
  min-height: 900px;
  max-height: 1600px;
  object-fit: cover;
  z-index: -1;
}

.banner-heading {
  margin-top: 400px;
  color: hsl(0, 0%, 100%);
  font-size: 50px;
  font-weight: 100;
  margin-bottom: 10px;
  font-family: 'Raleway';
}

.banner-info {
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.banner-button {
  vertical-align: top;
  display: inline-block;
  background-color: #1169a8;
  margin-right: 20px;
  border: none;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 800;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 18px;
  transition: all 0.5s ease-out;
  border: 1px solid transparent;
}

.banner-button:hover {
  background-color: rgb(28, 28, 32);
  color: white;
  box-shadow: 0px 0px 20px 10px #212427a9;
  border: 1px solid #313131;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.banner-button-alt {
  background-color: #6588a19f;
}

.banner-button-icon {
  display: inline-block;
  vertical-align: top;
  font-family: 'Material Icons';
}

.banner-button-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .banner-heading {
    font-size: 25px;
  }
  
  .banner-info {

  }
}


@media screen and (max-width: 1500px) {

  .banner-heading {
    font-size: 35px;
  }

  .banner-info {
    font-size: 20px;
  }

  .banner-card {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 25px;
  }  

  .banner-card-text {
    font-size: 13px;
  }

  .banner-cards {
    max-width: 100%;
  }

  .banner-button {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {

  .banner {
    margin-top: -200px;
  }

  .banner-container {
    margin-left: 25px;
    width: calc(100vw - 40px);
    text-align: center;
  }

  .banner-landing {
    height: 450px;
  }

  .banner-heading {
    font-size: 32px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .banner-info {
    font-size: 16px;
  }
  
  .banner-image {
    height: 450px;
  }

  .banner-button {
    font-size: 15px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    margin-top: 5px;
  }

}

.banner-cards {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.banner-card {
  background-color: white;
  display: inline-block;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #1169a857;
  -webkit-animation: 1s reveal ease-in;
          animation: 1s reveal ease-in;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

.banner-card-text {
  margin-top: 20px;
  color: #1169a8;
  font-weight: 700;
}

@media screen and (max-width: 1000px) {
  .banner-card {
    display: block;
    margin-top: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: none;
    box-shadow: 0px 0px 20px 5px #d8d8d87e;
  }  

  .banner-cards {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
    display: block;
  }

  .banner-heading {
    font-size: 25px;
  }
}


@media screen and (min-width: 1000px) {
  .banner-cards {
    width: 1000px;
    margin-left: 50vw - 500px;
  }
}

@media screen and (min-width: 1500px) {
  .banner-cards {
    width: 80%;
  }
}


@media screen and (max-width: 1000px) {
  .delivery-image {
    width: 100%;
  }
}


.prices {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;
  border-radius: 5px;
  text-align: left;
}

.prices-heading {
  font-size: 21px;
  font-weight: 800;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 10px;
}

.prices-table {
  border: 1px solid #dddddd;
  border-radius: 6px;
  width: 100%;
}

.prices-heading-row-dark {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 0px;
}

.prices-heading-heading {
  width: 100%;
  -webkit-column-span: 2;
          column-span: 2;
  color: black;
}

@media screen and (max-width: 800px) {
  .prices {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

.prices-heading-row {
  height: 40px;
  border-top: 1px solid #dddddd;
}

.prices-heading-heading {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 18px;
}

.prices-heading-element {
  padding-left: 20px;
  width: 50%;
  font-size: 14px;
}

.alt {
  background-color: #f8f8f8;
}

.delivery {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.delivery-image {
  width: 100%;
}

.delivery-info {
  font-weight: 500;
  font-size: 13px;
}

.delivery-right {
  margin-bottom: 80px;
}

.delivery-contact-set {
  font-weight: 800;
}

@media screen and (min-width: 1000px) {
  .delivery {
    text-align: left;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .delivery-right {
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 20%;
    margin-bottom: 0px;
  }
}

.footer {
  margin-top: 20px;
  margin-bottom: -70px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #1c1e1f;
  color: white;
}

.footer-logo-icon {
  margin-left: 20px;
  width: 70px;
}

.footer-name {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 40px;
}

.footer-card {
  padding-left: 20px;
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
}

.footer-card-icon {
  width: 20px;
  margin-right: -40px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.footer-card-text {
  font-size: 13px;
  font-weight: 800;
  color: #dfdfdf;
  padding-top: 40px;
}

.footer-copyright {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 11px;
  font-weight: 800;
  padding-bottom: 20px;
  border-bottom: 1px solid #3f3f3f;
}

@media screen and (min-width: 1000px) {
  .footer {
    padding-top: 20px;
  }

  .footer-cards {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: -40px;
    margin-bottom: -10px;
  }
}

body {
  -webkit-animation: appear 0.5s ease-in;
          animation: appear 0.5s ease-in;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes reveal {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes reveal {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}




