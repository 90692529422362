@import "./font/index.css";
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

body {
  width: 100vw;
  white-space: normal;
  color: #080808;
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 50px;
  background-color:#ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes reveal {
  from {
    filter: blur(10px);
    transform: translate(0px, 40px);
    transform: rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }

  to { 
    filter: blur(0);
    transform: translate(0px, 0px);
    transform: rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.4s;
}
